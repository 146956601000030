import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "./store";
import axios, { AxiosRequestConfig } from "axios";
import { API_URL, SSO_URL } from "@/utils";
import { UserToken } from "@/interfaces/data";
import { ManagerOptions, Socket, SocketOptions, io } from "socket.io-client";
import { useEffect, useRef } from "react";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const APICall = (config: AxiosRequestConfig & { token?: UserToken }) => {
  const headers = config.token
    ? { ...config.headers, Authorization: `Bearer ${config.token}` }
    : config.headers;

  return axios({
    ...config,
    baseURL: API_URL,
    withCredentials: true,
    headers,
  });
};

export const SSOCall = (config: AxiosRequestConfig & { token?: UserToken }) => {
  const headers = config.token
    ? { ...config.headers, Authorization: `Bearer ${config.token}` }
    : config.headers;
  return axios({
    ...config,
    baseURL: SSO_URL,
    withCredentials: true,
    headers,
  });
};

export const useSocket = (
  opts?: Partial<ManagerOptions & SocketOptions> | undefined
): Socket => {
  const { current: socket } = useRef(io(API_URL, opts));

  useEffect(() => {
    return () => {
      if (socket) socket.close();
    };
  }, [socket]);

  return socket;
};

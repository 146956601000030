import { SocialPlatforms } from "@/interfaces/data";
import { PersonalProfileSchema } from "@/schemas/CardSchema/CardSchema";
import { AxiosError, AxiosResponse } from "axios";
import Resizer from "react-image-file-resizer";
import { ImageListType } from "react-images-uploading";
import * as Yup from "yup";

export const IS_PRODUCTION = Boolean(process.env.NODE_ENV === "production");

// for GOOGLE MAPS URL
// replace spaces with "+"
export function mapsQuery(str: string): string {
  return str.replace(/ /g, "+");
}

export const addOrdinalSuffix = (i: number): string => {
  if (!i) return "";

  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
};

/** The API URL */
export const API_URL: string = IS_PRODUCTION
  ? "https://api.lynknow.com"
  : "http://localhost:9342";
/** The SSO URL */
export const SSO_URL: string = IS_PRODUCTION
  ? "https://sso.lynknow.com"
  : "http://localhost:9341";

export const MAIN_URL: string = IS_PRODUCTION
  ? "https://app.lynknow.com"
  : "http://localhost:3000";

export type ErrorResponse = {
  code?: number;
  info?: string;
  message?: string;
  error?: object;
};

/** Function to display error from API
 * @returns err.response.data.info (string)
 */
export const displayError = (err: unknown) => {
  if (typeof err === "string") {
    return err;
  }

  if ((err as AxiosError)?.response?.data === "Unauthorized") {
    window.location.replace("/logout");
  }

  return ((err as AxiosError)?.response?.data as ErrorResponse)?.info;
};

export const SOCIAL_BUTTONS: {
  social: SocialPlatforms;
}[] = [
  {
    social: "angellist",
  },
  {
    social: "facebook",
  },
  {
    social: "instagram",
  },
  {
    social: "linked-in",
  },
  {
    social: "odnoklassniki",
  },
  {
    social: "pinterest",
  },
  {
    social: "reddit",
  },
  {
    social: "slack",
  },
  {
    social: "skype",
  },
  {
    social: "renren",
  },
  {
    social: "snapchat",
  },
  {
    social: "telegram",
  },
  {
    social: "tumblr",
  },
  {
    social: "viadeo",
  },
  {
    social: "vk",
  },
  {
    social: "weibo",
  },
  {
    social: "wechat",
  },
  {
    social: "xing",
  },
];

export const getSocialBgColor = (
  platform: SocialPlatforms,
  stylesMode?: boolean
): string => {
  switch (platform) {
    case "angellist":
      return stylesMode ? "#000000" : "bg-black";
    case "facebook":
      return stylesMode ? "#3c69e8" : "bg-[#3c69e8]";
    case "instagram":
      return stylesMode ? "#e0346e" : "bg-[#e0346e]";
    case "whatsapp":
      return stylesMode ? "#36d169" : "bg-[#36d169]";
    case "linked-in":
      return stylesMode ? "#1b41aa" : "bg-[#1b41aa]";
    case "odnoklassniki":
      return stylesMode ? "#eb8138" : "bg-[#eb8138]";
    case "renren":
      return stylesMode ? "#245994" : "bg-[#245994]";
    case "reddit":
      return stylesMode ? "#fc471e" : "bg-[#fc471e]";
    case "slack":
      return stylesMode ? "#72cadb" : "bg-[#72cadb]";
    case "vk":
      return stylesMode ? "#47678d" : "bg-[#47678d]";
    case "wechat":
      return stylesMode ? "#7db239" : "bg-[#7db239]";
    case "weibo":
      return stylesMode ? "#cd343a" : "bg-[#cd343a]";
    case "pinterest":
      return stylesMode ? "#d43739" : "bg-[#d43739]";
    case "viadeo":
      return stylesMode ? "#ee745a" : "bg-[#ee745a]";
    case "snapchat":
      return stylesMode ? "#fffa37" : "bg-[#fffa37]";
    case "skype":
      return stylesMode ? "#1db0ee" : "bg-[#1db0ee]";
    case "telegram":
      return stylesMode ? "#138cd1" : "bg-[#138cd1]";
    case "tumblr":
      return stylesMode ? "#36475b" : "bg-[#36475b]";
    case "xing":
      return stylesMode ? "#0a605e" : "bg-[#0a605e]";
    default:
      return stylesMode ? "#000000" : "bg-black";
  }
};

/** Checks if an array contains empty string or not
 * @requires arr - the array to be checked
 */
export const arrNoneEmptyStr = (arr: any[]): boolean => {
  for (var i = 0; i < arr.length; i++) {
    if (arr[i] === "") return false;
  }
  return true;
};

export function dataURLtoFile(dataurl: string, fileObj: File) {
  var arr = dataurl.split(","),
    // @ts-expect-error
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  const blob = new Blob([u8arr], { type: mime });
  const fileName = fileObj.name.replace(/\.[^/.]+$/, "") + ".png";

  return new File([blob], fileName, { type: mime });
}

export const shortenFileName = (file: string | File) => {
  if (typeof file !== "string") {
    return file.name.toString().length > 25
      ? "....." +
          file.name
            .toString()
            .substring(
              file.name.toString().length - 20,
              file.name.toString().length
            )
      : file.name;
  } else {
    return file.toString().length > 25
      ? "....." +
          file
            .toString()
            .substring(file.toString().length - 20, file.toString().length)
      : file;
  }
};

export const displayFileName = (
  file?: File | string,
  value?: string,
  placeholder?: string
) => {
  // If 'file' is present, return its shortened name. Otherwise, check 'value', and finally fallback to 'placeholder'.
  const name = file ? file : value;
  return name ? shortenFileName(name) : placeholder;
};

export const getDataURL = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const resizeFile = (
  image: File,
  maxWidth?: number,
  maxHeight?: number,
  /** Compress format: PNG/JPEG for example
   * @default "PNG"
   */
  compressFormat?: string
) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      image,
      maxWidth ?? 1280,
      maxHeight ?? 720,
      compressFormat ?? "PNG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

export async function convertFormikValuesToFormData<T>(
  values: Partial<T>
): Promise<FormData> {
  const formData = new FormData();

  for (const [key, value] of Object.entries(values)) {
    // for avatar, just append the file
    if (["avatar", "logo"].includes(key) && (value as ImageListType).length) {
      formData.append(key, (value as ImageListType)[0].file as File);
    } else if (value || (Array.isArray(value) && value.length > 0)) {
      if (value instanceof File) {
        formData.append(key, value);
      } else if (
        typeof value === "object" &&
        value !== null &&
        !Array.isArray(value)
      ) {
        for (const [nestedKey, nestedValue] of Object.entries(value)) {
          if (nestedValue !== null && nestedValue !== "") {
            formData.append(`${key}[${nestedKey}]`, nestedValue.toString());
          }
        }
      } else if (Array.isArray(value)) {
        // if array then just stringify
        formData.append(`${key}`, JSON.stringify(value));
      } else {
        // For non-array values
        if (typeof value === "string" && value !== "") {
          formData.append(key, value);
        }
      }
    }
  }

  // Include any additional asynchronous operations here before returning formData

  console.log("Form data", formData);
  return formData;
}

// Helper function to determine if a string is a URL
export const isURL = (value: any): boolean =>
  typeof value === "string" && /^(http:\/\/|https:\/\/)/.test(value);

import { onLogout } from "@/app/user/userSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { GetServerSideProps, NextPage } from "next";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { IS_PRODUCTION } from "../utils";

const LogoutPage: NextPage = () => {
  const dispatch = useAppDispatch();
  const Router = useRouter();

  const { token: userToken } = useAppSelector((state) => state.user.data);

  useEffect(() => {
    if (userToken) {
      dispatch(onLogout());
    }
    Router.replace("/login");
  }, [userToken, dispatch]);

  return <div />;
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  context.res.setHeader(
    "Set-Cookie",
    IS_PRODUCTION
      ? `sso-lynknow=deleted; Max-Age=0; path=/; domain=.lynknow.com`
      : `sso-lynknow=deleted; Max-Age=0; path=/`
  );

  return {
    props: {},
  };
};

export default LogoutPage;
